@font-face {
    font-family: "DGM";
    src: url("./neodgm.woff");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

body,
html,
#root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    /*font-family: "Open Sans", serif;*/
    font-family: 'Gothic A1', sans-serif;

}

#root {
    filter: saturate(1.15) hue-rotate(345deg);
}

#root * {
    display: flex;
    align-items: center;
    justify-content: center;
}


@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

canvas {
    opacity: 0;
    touch-action: none;
    animation: fade-in 1s ease 0.3s forwards;
}

canvas:active {
    cursor: grabbing;
}
